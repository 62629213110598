import React, { useCallback, useEffect, useState } from "react";
import { Button, Space, Layout, Table } from "antd";
import { LoginForm } from "./LoginForm/index.js";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined, PlusOutlined } from "@ant-design/icons";
import AddProjectModal from "./AddProjectModal/index.js";
import "./index.css";
import { Header } from "../../common/Header/index.js";
import { putData } from "../../tools.js";

const { Content } = Layout;

export const MainPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : undefined);
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchData = useCallback(async () => {
    const response = await fetch(
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? "https://api.refbooster.com/project/list"
        : "http://localhost:1025/project/list",
      {
        method: "GET",
        mode: process.env.REACT_APP_ENVIRONMENT === "production" ? "cors" : undefined,
      }
    );
    const data = await response.json();
    setData(data);
  }, []);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    fetchData(); // Refresh data after closing the modal
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: () => (
        <>
          Project{" "}
          <Button style={{ marginLeft: 8 }} onClick={openModal} icon={<PlusOutlined/>}/>
        </>
      ),
      dataIndex: "image",
      key: "image",
      render: (text, record) => (
        <img src={record.imageUrl} alt={record.title} style={{ width: "100px", height: "100px" }} />
      ),
    },
    {
      title: "Info",
      dataIndex: "info",
      key: "info",
      render: (text, record) => (
        <div>
          <a href={record.link} target="_blank" onClick={async () => {
            putData('/user', { 
              linkOwnerId: record.linkOwnerId,
              refferalId: user._id,
              refferalTelegramName: user.telegramName
            })
            .then((data) => {
              console.log(data);
             
            })
            .catch((error) => {
              
              console.error(error);
            });
          }}>Join Project</a>
          <br />
          {/* <div>
            Video Guide:
            <ul>
              {record.guideLinks.split(",").map((link, index) => (
                <li key={index}>{link}</li>
              ))}
            </ul>
          </div>
          <div>
            <button onClick={() => toggleInfo(record.id)}>+</button>
            <div id={`info-${record.id}`} style={{ display: "none" }}>
              {record.otherInfo}
            </div>
          </div> */}
        </div>
      ),
    },
  ];

  const toggleInfo = (id) => {
    const infoDiv = document.getElementById(`info-${id}`);
    if (infoDiv.style.display === "none") {
      infoDiv.style.display = "block";
    } else {
      infoDiv.style.display = "none";
    }
  };

  return (
    <Layout>
      <Header />
      <Content>
        {user ? <Table columns={columns} dataSource={data} rowKey="id" /> : <LoginForm setUser={setUser} />}
      </Content>
      <AddProjectModal visible={modalVisible} onClose={closeModal} />
    </Layout>
  );
};
