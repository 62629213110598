import queryString from "query-string";

export async function postData(url = "", data = {}) {
  // Determine the base URL based on the environment
  const baseUrl =
    process.env.REACT_APP_ENVIRONMENT === "production" ? "https://api.refbooster.com" : "http://localhost:1025";

  // Construct the full URL
  const fullUrl = baseUrl + url;

  // Set up the fetch options
  const options = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: queryString.stringify(data), // body data type must match "Content-Type" header
  };

  // Execute the fetch request
  const response = await fetch(fullUrl, options);

  // Check if the response is successful
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  // Parse and return the JSON response
  return await response.json();
}

export async function putData(url = "", data = {}) {
  const baseUrl =
    process.env.REACT_APP_ENVIRONMENT === "production" ? "https://api.refbooster.com" : "http://localhost:1025";

  // Construct the full URL
  const fullUrl = baseUrl + url;

  // Set up the fetch options
  const options = {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: queryString.stringify(data), // body data type must match "Content-Type" header
  };

  // Execute the fetch request
  const response = await fetch(fullUrl, options);

  // Check if the response is successful
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  // Parse and return the JSON response
  return await response.json();
}
