import { Button, Space, Input, Form, Layout, InputNumber, notification, Tabs, Table } from "antd";
import { Content, Header as AntdHeader } from "antd/es/layout/layout.js";
import { LogoutOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export const Header = ({ child }) => {
  const navigate = useNavigate();
  // navigate("/");

  return (
    <AntdHeader
      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", position: "fixed", width: "100%" }}
    >
      <Space>
        <Button
          onClick={() => {
            navigate("/");
          }}
          icon={<HomeOutlined style={{ fontSize: 16 }} />}
        >
          Home
        </Button>
        <Button
          onClick={() => {
            navigate("/profile");
          }}
          icon={<UserOutlined style={{ fontSize: 16 }} />}
        >
          Profile
        </Button>
        {localStorage.getItem("user") && (
          <Button
            disabled={!localStorage.getItem("user")}
            onClick={() => {
              localStorage.removeItem("user");
              navigate("/");
              window.location.reload();
            }}
            icon={<LogoutOutlined style={{ fontSize: 16 }} />}
          >
            Logout
          </Button>
        )}
      </Space>
    </AntdHeader>
  );
};
