import { Button, Space, Input, Form, Layout, InputNumber, notification, Tabs, Table, Modal, Select } from "antd";
import { postData, putData } from "../../tools.js";
import { Content } from "antd/es/layout/layout.js";
import { LogoutOutlined, HomeOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { Header } from "../../common/Header/index.js";

export const ProfilePage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : undefined);
  const [referralPride, setReferralPride] = useState();
  const [projectList, setProjectList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  if (!user) {
    navigate("/");
  }

  const fetchData = useCallback(async () => {
    const listResp = await fetch(
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? "https://api.refbooster.com/project/list"
        : "http://localhost:1025/project/list",
      {
        method: "GET",
        mode: process.env.REACT_APP_ENVIRONMENT === "production" ? "cors" : undefined,
      }
    );
    const list = await listResp.json();
    const response = await fetch(
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? `https://api.refbooster.com/user?id=${user._id}`
        : `http://localhost:1025/user?id=${user._id}`,
      {
        method: "GET",
        mode: process.env.REACT_APP_ENVIRONMENT === "production" ? "cors" : undefined,
      }
    );
    const data = await response.json();
    setProjectList(list);
    setReferralPride(data.referralPride);
    setUser(data);
  }, [user._id]);

  useEffect(() => {
    if (user) localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddProject = async (values) => {
    try {
      const response = await postData("/user", values);
      notification.success({ message: "Project added successfully!" });
      setIsModalVisible(false);
      form.resetFields();
      fetchData(); // Refresh data after adding project
    } catch (error) {
      notification.error({ message: "Failed to add project" });
    }
  };

  const profile = <Space>{/* Profile content can be added here */}</Space>;

  const referralTable = (
    <Table
      title={() => (
        <div>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)}>
            Add Project
          </Button>
        </div>
      )}
      footer={() => `Points: ${user.points}`}
      bordered
      size="small"
      dataSource={user.projects || []}
      columns={[
        {
          title: "Your Projects",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "Your Referrals",
          key: "referrals",
          render: (record) => {
            const stats = user.stats.filter(({ projectId }) => projectId === record._id);

            return (
              <>
                {stats.map(({ refferalTelegramName, userId }) => (
                  <div>{refferalTelegramName || userId}</div>
                ))}
              </>
            );
          },
        },
        {
          title: "Referral Confirmation",
          dataIndex: "confirmation",
          key: "confirmation",
        }
      ]}
    />
  );

  const tabs = [
    {
      key: "3",
      label: "Referral Table",
      children: referralTable,
    },
    {
      key: "1",
      label: "Profile",
      children: profile,
    },
  ];

  return (
    <Layout>
      <Header />
      <Content>
        <a target="_blank" href={referralPride}>
          {referralPride}
        </a>

        <Tabs items={tabs} />
        <Modal title="Add Project" visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
          <Form form={form} onFinish={handleAddProject}>
            <Form.Item name="userId" label="User ID" initialValue={user._id} rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
            <Form.Item name="projectId" label="Project ID" rules={[{ required: true }]}>
              <Select>
                {projectList.map((project) => (
                  <Select.Option key={project._id} value={project._id}>
                    {project.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="refferalLink" label="Referral Link" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Content>
    </Layout>
  );
};
