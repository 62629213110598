import React, { useState } from "react";
import { Modal, Form, Input, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";

const { TextArea } = Input;

const AddProjectModal = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("guideLinks", values.guideLinks);
      console.log(file);
      if (file) {
        formData.append("image", file);
      }

      setLoading(true);
      const baseUrl =
        process.env.REACT_APP_ENVIRONMENT === "production"
          ? "https://api.refbooster.com"
          : "http://localhost:1025";
      const response = await axios.post(baseUrl + "/project", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      onClose();
    } catch (error) {
      console.error("Failed to submit form:", error);
      setLoading(false);
    }
  };

  const handleFileChange = (info) => {
    console.log(info)
      setFile(info.file);
  };

  return (
    <Modal
      title="Add New Project"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="title" label="Title" rules={[{ required: true, message: "Please input the title!" }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please input the description!" }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="guideLinks"
          label="Guide Links"
          rules={[{ required: true, message: "Please input the guide links!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Upload Image" valuePropName="fileList">
          <Upload
            name="image"
            listType="picture"
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleFileChange}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProjectModal;
