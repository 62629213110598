import { Input, Form, Space, Button, notification } from "antd";
import { useState } from "react";
import { postData } from "../../../tools.js";
import { useLocation } from "react-router-dom";

export const LoginForm = ({ setUser }) => {
  const [isLogin, setIsLogin] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralPride = queryParams.get("referralPride");

  return (
    <Form
      name="login"
      layout="vertical"
      style={{ maxWidth: 800 }}
      onFinish={(values) => {
        const url = isLogin ? "/login" : "/register";
        postData(url, { ...values, ...(!isLogin && referralPride ? {referralPride} : {}) })
          .then((data) => {
            console.log(data);
            localStorage.setItem("user", JSON.stringify(data));
            setUser(data);
            if (!isLogin) {
              notification.success({ message: "Registration successful" });
              window.location.reload();
            }
          })
          .catch((error) => {
            if (!isLogin) {
              notification.error({ message: "User with this phone/login already exists" });
            }
            console.error(error);
          });
      }}
    >
      {isLogin ? <h2>Login</h2> : <h2>Register</h2>}

      <Form.Item
        label="Login"
        name="login"
        rules={[{ required: true, message: "Please enter your login!" }]}
      >
        <Input placeholder="example@gmail.com" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please enter your password!" }]}
      >
        <Input.Password placeholder="********" />
      </Form.Item>

      {!isLogin && (
        <Form.Item
          label="Telegram Name"
          name="telegramName"
          rules={[{ required: true, message: "Please enter your Telegram name!" }]}
        >
          <Input placeholder="@example" />
        </Form.Item>
      )}

      <Form.Item>
        <Space>
          {isLogin ? (
            <Button type="text" onClick={() => setIsLogin(false)}>Register</Button>
          ) : (
            <Button type="text" onClick={() => setIsLogin(true)}>Login</Button>
          )}
          <Button type="primary" htmlType="submit">
            {isLogin ? "Login" : "Register"}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
